import React, { /*useState, useMemo, useCallback, useEffect*/ } from "react";
// import * as apiPublic from "@api/public";
import styles from "./AdminPagamenti.module.css";

const AdminPagamenti = () => {

  return (
    <>
      <span>Gestione pagamenti</span>
      <div className={styles.wrapperAnnunci}>
        
      </div>
    </>
  );
};

export default AdminPagamenti;
