import React from "react";
// import Icon from "@components/icon";
import Logo from "@components/svgs/Logo";
import styles from "./AppFooter.module.css";

const AppTopBar = () => {
  return (
    <div className={styles.footer}>
      <div style={{ padding: "0 18px" }}>
        <svg aria-hidden="true" width="100%" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <pattern id="a" width="91" height="8" patternUnits="userSpaceOnUse">
            <g clipPath="url(#clip0_2426_11367)">
              <path d="M114 4c-5.067 4.667-10.133 4.667-15.2 0S88.667-.667 83.6 4 73.467 8.667 68.4 4 58.267-.667 53.2 4 43.067 8.667 38 4 27.867-.667 22.8 4 12.667 8.667 7.6 4-2.533-.667-7.6 4s-10.133 4.667-15.2 0S-32.933-.667-38 4s-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0" stroke="#E1E3E1" strokeLinecap="square"></path>
            </g>
          </pattern>
          <rect width="100%" height="100%" fill="url(#a)"></rect>
        </svg>
      </div>
      <div className={styles.footerContent}>
        <Logo className={styles.logo} />
        <div>
          {/* <Icon
            className={styles.icon}
            name="domain"
            size={20}
            fill={0}
            weight={400}
            grade={0}
            opticalSize={24}
          /> */}
          <span>La Soffiata, Crema, 26013 (CR)</span>
        </div>
        <div>
          {/* <Icon
            className={styles.icon}
            name="business_center"
            size={20}
            fill={0}
            weight={400}
            grade={0}
            opticalSize={24}
          /> */}
          <span>p.iva 01722520192</span>
        </div>
        <div>
          {/* <Icon
            className={styles.icon}
            name="call"
            size={20}
            fill={0}
            weight={400}
            grade={0}
            opticalSize={24}
          /> */}
          <span>Tel. 0373 250642</span>
        </div>
        <div>
          {/* <Icon
            className={styles.icon}
            name="phone_android"
            size={20}
            fill={0}
            weight={400}
            grade={0}
            opticalSize={24}
          /> */}
          <span>Cel. 329 3258837</span>
        </div>
        <div>
          {/* <Icon
            className={styles.icon}
            name="mail"
            size={20}
            fill={0}
            weight={400}
            grade={0}
            opticalSize={24}
          /> */}
          <span>info@lasoffiata.it</span>
        </div>
      </div>
    </div>
  );
};

export default AppTopBar;
